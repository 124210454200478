<template>
  <div class="person">
    <!-- EditHead -->
    <EditHead :headName="[(() => id ? !!type ? '修改密码' : '编辑人员' : '添加人员')()]" @handleRoute="handleRoute"></EditHead>
    <!-- container -->
    <div class="container">
      <!-- title -->
      <div class="title">
        <div class="text">{{ id ? !!type ? '修改密码' : '编辑人员' : '添加人员' }}</div>
        <div class="desc">{{ id ? !!type ? '修改管理人员密码' : '编辑管理人员' : '添加管理人员' }}</div>
      </div>
      <!-- form -->
      <div class="form">
        <el-form ref="personForm" :model="personForm" label-width="80px" :rules="rules">
          <el-form-item label="姓名" prop="user_name">
            <el-input v-model="personForm.user_name" placeholder="请输入名称" :disabled="!!type"></el-input>
          </el-form-item>
          <!-- 绑定企业微信暂不需要 - 暂未实现 -->
          <!-- <el-form-item label="绑定企业微信">
            <el-input v-model="personForm.name"></el-input>
          </el-form-item> -->
          <el-form-item label="登录账号" prop="passport">
            <el-input v-model="personForm.passport" placeholder="请输入登录账号" :disabled="!!type"></el-input>
          </el-form-item>
          <el-form-item :label="!!type ? '新密码' : '密码'" prop="password" v-if="(!id || !!type)">
            <el-input type="password" v-model="personForm.password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input type="number" v-model="personForm.mobile" placeholder="请输入手机号" :disabled="!!type"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="personForm.email" placeholder="请输入邮箱" :disabled="!!type"></el-input>
          </el-form-item>
          <el-form-item label="角色" prop="roles">
            <el-select v-model="personForm.roles" placeholder="请选择角色" multiple :disabled="!!type">
              <el-option v-for="item in rolesList" :key="item.id" :value="item.id" :label="item.role_name"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <!-- footer -->
      <diy-footer-button v-if="id ? hasPerm(['admin.update']) : hasPerm(['admin.store'])">
        <el-button type="primary" @click="handleSave">保存</el-button>
      </diy-footer-button>

    </div>
  </div>
</template>
<script>
import { Message } from 'element-ui';

export default {
  data() {
    return {
      personForm: {
        user_name: "", //姓名
        passport: "",//登录账号
        password: "",//登录密码
        mobile: "",//手机号
        email: "",//邮箱
        roles: [],//角色列表
      },//form
      rolesList: [],//角色列表
      rules: {
        user_name: [{ required: true, validator: this.userNameValid, trigger: 'blur' }],
        passport: [{ required: true, validator: this.passPortValid, trigger: 'blur' }],
        password: [{ required: true, validator: this.passwordValid, trigger: 'blur' }],
        mobile: [{ required: true, validator: this.mobileValid, trigger: 'blur' }],
        email: [{ required: true, validator: this.emailValid, trigger: 'blur' }],
        roles: [{ required: true, type: "array", message: "请选择角色!", trigger: ['change,blur'] }],
      }, //rule
    }
  },
  props: ['id', 'type'],
  created() {
    this.getAllPermissionList();
    this.getUserInfo();
  },
  methods: {
    // 自定义规则校验
    // 姓名
    userNameValid(rules, value, callback) {
      const reg = /^(?:[\u4e00-\u9fa5·]{2,4})$/ // 支持中文
      if (value == "") {
        callback(new Error("请输入姓名!"));
      }
      if (!reg.test(value)) {
        // callback(new Error("格式错误，请输入2-4个中文字符!"));
        callback();
      }
      callback();
    },
    // 账号
    passPortValid(rules, value, callback) {
      const reg = /^[\w-]{4,16}$/; // 4-16位（字母，数字，下划线，减号）
      if (value == "") {
        callback(new Error("请输入账号!"));
      }
      if (!reg.test(value)) {
        callback(new Error("请输入4-16个字符!(字母，数字，下划线，减号)"));
      }
      callback();
    },
    // 密码
    passwordValid(rules, value, callback) {
      const reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]/; //大写字母，小写字母，数字，特殊符号 `@#$%^&*`~()-+=` 中任意3项密码
      if (value == "") {
        callback(new Error(`请输入${!!this.type ? '新' : ''}密码!`));
      }
      // if (!reg.test(value)) {
      //   callback(new Error("大写字母，小写字母，数字，特殊符号 `@#$%^&*`~()-+=` 中任意3项密码"));
      //   // callback();
      // }
      if (value.length < 6 || value.length > 50) {
        callback(new Error("密码长度在6-50位字符之间!"));
      }
      callback();
    },
    // 手机号
    mobileValid(rules, value, callback) {
      const reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/; //手机号(mobile phone)中国(严谨), 根据工信部2019年最新公布的手机号段
      if (value == "") {
        callback(new Error("请输入手机号!"));
      }
      if (!reg.test(value)) {
        callback(new Error("输入格式错误，请重新输入!"));
      }
      callback();
    },
    // 邮箱
    emailValid(rules, value, callback) {
      const reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/; //email(支持中文邮箱)
      if (value == "") {
        callback(new Error("请输入邮箱!"));
      }
      if (!reg.test(value)) {
        callback(new Error("输入格式错误，请重新输入!"));
      }
      callback();
    },
    // 获取所有权限
    async getAllPermissionList() {
      const { data } = await this.$get(this.$apis.obtain);
      this.rolesList = data;
    },
    // 返回,清空form并返回列表页
    handleRoute() {
      this.$refs['personForm']['resetFields']();
      this.$router.push("/setup/management/staff");
    },

    // 获取用户信息
    getUserInfo() {
      if (this.id) {
        this.$get(`${this.$apis.deletStaff}${this.id}`).then(res => {
          if (res.code == 200) {
            this.$nextTick(() => {
              this.$set(this, 'personForm', { ...res.data, password: "" });
            })
          } else {
            Message.error("获取用户信息失败!")
          }
        })
      }
      return;
    },

    // 保存
    handleSave() {
      this.$refs['personForm']['validate'](async valid => {
        if (valid) {
          if (!this.type) {
            const { code, message } = await this[(() => this.id ? '$put' : '$post')()](`${this.$apis.increAsed}${this.id ? '/' + this.id : ''}`, { ...this.personForm });
            if (code == 200) {
              Message.success(`${this.id ? '编辑' : '添加'}员工成功!`);
              // 清除并返回
              this.handleRoute();
            } else {
              Message.error(`${this.id ? '编辑' : '添加'}员工失败!-[${message}]`);
            }
          } else {
            // 修改密码
            const { code } = await this.$put(`${this.$apis.xpassWord}/${this.id}`, { password: this.personForm.password });
            if (code == 200) {
              Message.success(`修改密码成功!`);
              // 清除并返回
              this.handleRoute();
            } else {
              Message.error(`${message}`);
            }
          }
        }
      })
    }
  },
  computed: {},
}
</script>

<style scoped lang="less">
.person {
  width: 100%;
  padding-bottom: 50px;

  & .container {
    width: 100%;
    padding: 32px 56px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 3px;

    & .title {
      width: 100%;
      // height: 32px;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      margin-bottom: 40px;

      & .text {
        // width: 64px;
        height: 20px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.9);
        line-height: 20px;
        margin-bottom: 10px;
      }

      & .desc {
        // width: 196px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
      }
    }

    & .form {
      width: 50%;

      & /deep/.el-form-item__label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }

      & /deep/.el-input__inner {
        // width: 356px;
        // height: 38px;
        // background: #FFFFFF;
        // border-radius: 2px;
        // border: 1px solid #DDDFE6;
        // box-sizing: border-box;

      }

      & /deep/.el-input.is-disabled .el-input__inner {
        background-color: #f5f7fa;
        border-color: #e4e7ed;
        color: #c0c4cc;
        cursor: not-allowed;
      }
    }

    & .addtion_foot {
      width: 100%;
      height: 60px;
      background: #FFFFFF;
      border: 1px solid #F1F1F1;
      position: fixed;
      bottom: 0;
      z-index: 2;
      left: 0;

      & .addtion_foot-body {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>